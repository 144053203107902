import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { IconButtonComponent } from '../../../shared/components/buttons/icon-button/icon-button.component';
import { AlertComponent } from '../../../shared/components/alert/alert.component';

@Component({
  selector: 'app-dialog-header',
  standalone: true,
  template: `
    <div class="px-6 pt-6">
      <div class="flex items-center justify-between">
        <h2 class="heading-title mb-0">{{ title }}</h2>
        <ng-content select="[close]"></ng-content>
      </div>

      @if (error) {
        <app-alert>{{ error }}</app-alert>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconButtonComponent, AlertComponent, MatDialogModule],
})
export class DialogHeaderComponent {
  @Input() title: string = '';
  @Input() error: string | null = null;

  @Output() closeEvent = new EventEmitter<void>();

  onClose = () => this.closeEvent.emit();
}
